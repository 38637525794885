/* styles/mainscreen.css */

@import url('https://fonts.googleapis.com/css2?family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

@font-face {
  font-family: 'Cowboy Hippie Pro';
  src: url('../fonts/cowboyhippiepro.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Light';
  src: url('../fonts/avenirlight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Avenir Light', sans-serif;
  background-color: #91B6B2;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navbar {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo {
  font-size: 50px;
  color: #333;
  font-weight: bold;
  font-family: 'Cowboy Hippie Pro', sans-serif;
}

.nav-links a {
  margin-left: 20px;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  transition: color 0.3s ease;
}

.nav-links a:hover,
.nav-links a:active {
  color: #494538;
}

/* Hero Section */
.hero-section {
  width: 100%;
  background-color: #E1CFB9; /* Background color for hero section */
  padding: 80px 20px; /* More vertical padding */
  display: flex;
  justify-content: center;
}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 900px; /* Constrained width to match the Wix layout */
  width: 100%;
  gap: 40px; /* Space between text and image */
}

.intro-section {
  text-align: left;
  max-width: 400px; /* Limit the width for text */
}

.intro-title {
  font-size: 50px;
  font-weight: bold;
  font-family: 'Suez One', serif;
}

.intro-title-part1 {
  color: #FFFFFF;
}

.intro-title-part2 {
  color: #494538;
}

.intro-text {
  font-size: 25px;
  color: #19221E;
  font-family: 'Avenir Light', sans-serif;
  margin: 10px 0 20px;
}

.download-buttons {
  display: flex;
  gap: 15px;
}

.download-button {
  width: 150px;
  cursor: pointer;
}

.image-section .coffee-image {
  width: 300px;
  max-width: 100%;
}

/* Story Section */
.story-section {
  width: 100%;
  background-color: #94b0a8; /* Background color for story section */
  padding: 80px 20px; /* More vertical padding for spacing */
  display: flex;
  justify-content: center;
}

.story-content {
  max-width: 600px; /* Match the Wix layout's content width */
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  padding: 40px; /* Padding inside the white content box */
  border-radius: 8px; /* Slight rounding for consistency */
}

.story-title {
  font-size: 40px; /* Set to 40px */
  font-weight: bold;
  font-family: 'Cowboy Hippie Pro', sans-serif;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 20px;
}

/* Style for the word "Brewser" within the title */
.story-title .highlight {
  color: #494538; /* Set Brewser to #494538 */
}

.story-text {
    font-size: 15px; /* Set to 15px */
    color: #19221E; /* Set color to #19221E */
    font-family: 'Avenir Light', sans-serif; /* Apply Avenir Light font */
    margin-bottom: 30px;
  }

.story-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.story-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #333;
  background-color: transparent;
  border: 1px solid #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.story-button:hover {
  background-color: #333;
  color: #fff;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #94b0a8; /* Custom color for footer links */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
