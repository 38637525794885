body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

/* Standardized Text Styles */
.title {
    font-size: 24px;
    font-weight: bold;
    color: #F57F17; /* Yellow 900 for title text */
    text-align: center;
    margin: 10px 0; /* Adjusted for compact layout */
  }
  
  .header {
    font-size: 18px;
    font-weight: bold;
    color: #F57F17; /* Yellow 900 for header text */
    margin-bottom: 5px;
  }
  
  .subtitle {
    font-size: 16px;
    color: #F57F17; /* Material Yellow 500 for contrast */
    margin-bottom: 10px;
    text-align: center;
  }
  
  .paragraph {
    font-size: 14px;
    color: #F57F17; /* Accent color (Yellow 900) */
  }
  
  .small-text {
    font-size: 12px;
    color: #F57F17; /* Accent color (Yellow 900) */
  }
  
  .instructions {
    font-size: 14px;
    color: #F57F17; /* Accent color (Yellow 900) */
    text-align: center;
    margin: 10px 0;
  }
  
  /* Group Title Style */
  .group-title {
    font-size: 20px;
    font-weight: bold;
    color: #F57F17; /* Yellow 900 for group title */
    margin: 15px 0;
    margin-left: 10px; /* Left alignment for group title */
  }
  
  /* Screen Background */
  .container {
    background-color: #F5F5F5; /* Light grey background */
    padding: 0;
  }
  
  .scroll-container {
    padding: 20px 0;
    overflow-y: auto; /* Scrollable container */
  }
  
  /* Customer Account Styles */
  .customer-card {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .customer-name {
    font-size: 18px;
    font-weight: bold;
    color: #F57F17;
    margin-bottom: 5px;
  }
  
  .customer-info {
    font-size: 14px;
    color: #333333;
  }
  
/* Login Screen Styles */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertical centering */
    align-items: center;     /* Horizontal centering */
    height: 100vh;           /* Full viewport height */
    width: 100vw;            /* Full viewport width */
    background-color: #F5F5F5;
  }
  
  .login-container .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .login-container .input {
    width: 100%;
    max-width: 300px;
    height: 40px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
  }
  
  .login-container .button {
    width: 100%;
    max-width: 300px;
    background-color: #F57F17; /* Yellow 900 for button background */
    padding: 10px;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }
  
  
  /* Menu Styles */
  .menu-container {
    display: flex;
    justify-content: space-around; /* Space items evenly */
    align-items: center;
    background-color: #FFFFFF;
    padding: 10px 0;
    border-bottom: 1px solid #DDDDDD;
  }
  
  .menu-item {
    font-size: 16px;
    color: #F57F17; /* Accent color */
    padding: 10px 15px;
  }
  
  .staff-card {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }
  